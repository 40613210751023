<template>
  <div class="svg-image" @mouseover="onMouseOver" @mouseout="onMouseOut">
    <div class="image-box" :style="{ position: 'relative', width: width + '' + sizeUnit, height: height + '' + sizeUnit, overflow: 'hidden' }">
      <img ref="image" :src="src" :style="normalStyle" />
    </div>
    <template v-if="label">
      <span class="label" :style="labelStyle">{{ label }}</span>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
    },

    width: {
      type: Number,
      default: 40,
    },

    height: {
      type: Number,
      default: 40,
    },

    sizeUnit: {
      type: String,
      default: "px",
    },

    color: {
      type: String,
      default: "#000000",
    },

    hoverColor: {
      type: String,
    },

    selectedColor: {
      type: String,
    },

    label: {
      type: String,
    },
  },

  data() {
    return {
      mouseover: false,
    };
  },

  emits:["selectedChanged"],

  computed: {
    labelStyle() {
      return {
        color: this.renderColor,
      };
    },

    normalStyle() {
      return {
        position: "absolute",
        top: "0",
        left: -1 * this.width + "" + this.sizeUnit,
        filter: "drop-shadow(" + this.width + "" + this.sizeUnit + " 0 " + this.renderColor + ")",
      };
    },

    renderColor() {
      const renderColor = this.mouseover ? this.hoverColor : this.color;
      return renderColor;
    },
  },

  methods: {
    onMouseOver() {
      this.mouseover = true;
    },
    onMouseOut() {
      this.mouseover = false;
    },
  },
};
</script>
<style lang="less" scoped>
.svg-image {
  border: none;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  img {
    height: 100%;
    object-fit: cover;
  }
}
</style>

<template>
  <router-view />
</template>

<script>
import {provide, reactive} from "vue"
export default {
  name: "App",
  mounted() {
    const that = this;
    window.onresize = () => (() => {
      that.$store.commit('increment', document.body.clientWidth)
    })();
  },

  setup() {
    const pageIndicator = {
      currentPageName:''
    }

    provide("pageIndicator", reactive(pageIndicator));
  },
};
</script>

<style>
#app {
  height: 100%;
  width: 100%;
}

body{
  /* background-color: #ECECEC; */
}
</style>

import { createRouter, createWebHistory } from 'vue-router'

// 1. 定义路由组件.
// 也可以从其他文件导入
const Index = () => import("@/layout")
const Home = () => import("@/pages/Home.vue")
const Home1 = () => import("@/pages/Home1.vue")
const Home2 = () => import("@/pages/Home2.vue")
const About = () => import("@/pages/About.vue")
const ContactInfo = () => import("@/pages/ContactInfo.vue")
const ProductCenter = () => import("@/pages/ProductCenter.vue")
const DetailImg = () => import("@/pages/ProductDetailImg.vue")
const DetailImgAndText = () => import("@/pages/ProductDetailImgAndText.vue")
const ServerCenter = () => import("@/pages/ServerCenter.vue")
const ServerCenterPDF = () => import("@/pages/ServerCenterPDF.vue")
const ServerCenterPDFPrinter = () => import("@/pages/ServerCenterPDFPrinter.vue")
// 2. 定义一些路由
// 每个路由都需要映射到一个组件。
// 我们后面再讨论嵌套路由。
const routes = [
  {
    path: '/',
    name: 'index',
    component: Index,
    redirect: '/home',//页面默认加载的路由
    children: [
      {
        path: '/home',
        name: 'home',
        component: Home
      },
      {
        path: '/home1',
        name: 'home1',
        component: Home1
      },
      {
        path: '/home2',
        name: 'home2',
        component: Home2
      },
      {
        path: '/about',
        name: 'about',
        component: About
      },
      {
        path: '/contact',
        name: 'contactInfo',
        component: ContactInfo
      },
      {
        path: '/product',
        name: 'product',
        component: ProductCenter,
      },
      {
        path: '/server',
        name: 'serverCenter',
        component: ServerCenter
      },
      {
        path: '/pdf',
        name: 'serverCenterPDF',
        component: ServerCenterPDF
      },
      {
        path: '/pdfprint',
        name: 'serverCenterPDFPrinter',
        component: ServerCenterPDFPrinter
      },
      {
        path: '/product/detailImg',
        name: 'detailImg',
        component: DetailImg
      },
      {
        path: '/product/detailImgAndText',
        name: 'detailImgAndText',
        component: DetailImgAndText
      },

    ]
  },

]

// 3. 创建路由实例并传递 `routes` 配置
// 你可以在这里输入更多的配置，但我们在这里
// 暂时保持简单
const router = createRouter({
  // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  history: createWebHistory(),
  routes, // `routes: routes` 的缩写
})
export default router;
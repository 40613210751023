import { createApp } from 'vue'
import App from './App.vue'
import router from './router'          //这里引入了router
import store from './store'
// ElementPlus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import SvgImage from "./components/SvgImage.vue";

// 项目初始化css
import './global.less';
import $ from 'jquery'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.component("svg-image", SvgImage);

app.use(ElementPlus)
app.use(router)
app.use(store)
app.use($);
app.mount('#app')